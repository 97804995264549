import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { signup, verify } from '../actions/auth'
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';



const Signup = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [submitted, setSubmitted] = useState(false)



    const initialState = {
        email:"",
        username:"",
        fullName:"",
        mobileNumber:"",
        password:"",
        confirmPassword:""
    }


    const [authData, setAuthData] = useState(initialState)
    const [code, setCode] = useState()

    
    const handleChange = (e) =>{
        setAuthData({...authData, [e.target.name]:e.target.value})
    }
    const handleChange2 = (e) =>{
        setCode(e.target.value)
    }
    
    const handleSubmit = (e)=>{
        e.preventDefault()
        const {password, confirmPassword} = authData
        if(password!==confirmPassword)
        alert("Passwords are not the same")
        
        
        // console.log(authData)
        if(Object.values(authData).includes(""))
            alert("Please fill all the fields")
        else
        dispatch(signup(authData, navigate))
        setSubmitted(true)
    }
    const handleVerify = (e) => {
        e.preventDefault()
        console.log(code)
        console.log(authData)
        dispatch(verify(code, navigate))
    }

    const {isLoading} = useSelector ((state)=>state.auth)

    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    const isMobile = width <= 768;
  return (
    <>
    {!submitted && <>
        <br/>
       <section >
           <div className="container mb-4">
               <div className="sign-form px-auto" style={isMobile?{border:'none',marginLeft:'7%',marginRight:'7%',width:'50vw'}:{}}>
                   <h2 style={isMobile?{marginLeft:'40%'}:{}} >Sign Up</h2>
               <form onSubmit={handleSubmit} style={isMobile ? { width: "150%" } : {}}>
                   <div className="row">
                       <div className="col-lg-12">
                           <input style={{color:'#333'}} type="email" name="email" id="email" placeholder="Email Id" onChange={handleChange}/>
                       </div>
                       
                        <div className="col-lg-6">
                           <input style={{color:'#333'}} type="text" name="username" id="username" placeholder="Username" onChange={handleChange}/>
                       </div>
                       <div className="col-lg-6">
                           <input style={{color:'#333'}} type="text" name="mobileNumber" id="mobileNumber" placeholder="Mobile no." onChange={handleChange}/>
                       </div>
                       <div className="col-lg-12">
                           <input style={{color:'#333'}} type="text" name="fullName" id="fullName" placeholder="Full Name" minLength="10" onChange={handleChange}/>
                       </div>
                       <div className="col-lg-6">
                           <input style={{color:'#333'}} type="password" name="password" id="password" placeholder="Password" onChange={handleChange}/>
                       </div>
                       <div className="col-lg-6">
                           <input style={{color:'#333'}} type="password" name="confirmPassword" id="confirmPassword" placeholder="Confirm Password" onChange={handleChange}/>
                       </div>
                       <div className="offset-lg-4 col-lg-4 justify-content-center">
                           <button type="submit" className="site-btn">Sign Up &nbsp; {isLoading && <CircularProgress style={{position:'absolute',color:'white'}}  size={25} disableShrink />} </button>
                       </div>
                   </div>
                   <div>
            <Typography style={{marginTop:'1em'}}>Already have an account? <Link style={{textDecoration:"none",color:'0x0B64BD',fontWeight:'bold'}} to={"/login"}> Click here to login </Link> </Typography>
           </div>
               </form>
               </div>     
           </div>
       </section>
       </>}
       {submitted && <>
        <br/>
       <section >
           <div className="container">
               <div className="sign-form px-4">
                   <h2>User Verification</h2>
                   <p className = "text-center  ">A verification code has been sent to {authData.email}</p>
               <form onSubmit={handleVerify}>
                   <div className="row">
                       <div className="col-lg-12">
                           <input style={{color:'#333'}} type="text" name="Verification Code" id="verify" placeholder="Verification Code" onChange={handleChange2}/>
                       </div>
                       <div className="offset-lg-4 col-lg-4 justify-content-center">
                           <button type="submit" className="site-btn">Verify &nbsp; {isLoading && <CircularProgress style={{position:'absolute',color:'white'}}  size={25} disableShrink />} </button>
                       </div>
                   </div>
               </form>
               </div>
               
           </div>
       </section>
       <br/>
       </>}
    </>
  )
}

export default Signup
