import React, { useEffect, useState } from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { enterBillDetails } from '../actions/order'


const OrderForm = ({setActiveStep}) => {

    // const user = JSON.parse(localStorage.getItem('profile'))


    // const initialState = {
    //     primaryAddress: '',
    //     secondaryAddress: '',
    //     pinCode: '',
    //     mobileNumber: user?.result?.mobileNumber ,
    // }

    const {billingFormDetails} = useSelector((state)=>state.order)
    const [data, setData] = useState(billingFormDetails)
    const dispatch = useDispatch()

    const handleChange = (e) => {

        setData({ ...data, [e.target.name]: e.target.value })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(data)
        if(Object.values(data).includes(''))
            alert("Fill out the form")
        else{
            dispatch(enterBillDetails(data))
            setActiveStep(1)
        }
    }

    const [width, setWidth] = useState(window.innerWidth);

const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

const isMobile = width <= 700;

    return (
        <>
            <br />
            <section >
                <div className="container" style={isMobile?{}:{width:'50vw'}}>
                    <div className={`sign-form ${isMobile?"px-auto":"px-2 mr-5 ml-5"}`} style={isMobile?{border:'none',marginLeft:'7%',marginRight:'7%',width:'50vw'}:{}} >
                        <h2 style={isMobile ? { width: "150%" } : {}} >Order Details</h2>
                        <form onSubmit={handleSubmit} style={isMobile ? { width: "150%" } : {}} >
                            <div className="form-row mb-0" >
                                <div className=" col-lg-12">
                                    <input onChange={handleChange} type="text" className="form-control" name="primaryAddress" value={data.primaryAddress} id="primaryAddress" placeholder='Primary Address' />
                                </div>
                                <div className=" col-lg-12">
                                    <input onChange={handleChange} type="text" className="form-control" name="secondaryAddress" value={data.secondaryAddress} id="secondaryAddress" placeholder='Secondary Address' />
                                </div>
                                <div className=" col-lg-12">
                                    <input onChange={handleChange} type="text" className="form-control" name="pinCode" value={data.pinCode} id="pinCode" placeholder='Pin Code' />
                                </div>
                                <div className=" col-lg-12">
                                    <input style={{color:'black'}} onChange={handleChange} type="text" className="form-control" name="mobileNumber" value={data?.mobileNumber} id="mobileNumber" placeholder='Phone Number' />
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary">Submit  </button>
                        </form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OrderForm
