import { END_LOADING, FETCH_CATEGORIES, FETCH_SUB_CATEGORIES, START_LOADING } from "../constants/actionTypes";


const categoryReducer = (state = {categories: [],subCategories: [], countC:0, countS:0, isLoading: false}, action)=>{
    switch (action.type) {
        case FETCH_CATEGORIES:
            return {...state, categories: action.payload.data, countC:action.payload.count};
        case FETCH_SUB_CATEGORIES:
            return {...state, subCategories: action.payload.data, countS:action.payload.count};
        case START_LOADING:
            return {...state, isLoading : true}
        case END_LOADING:
            return {...state, isLoading : false}
        default:
            return state;
    }
}

export default categoryReducer