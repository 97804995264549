import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import { Button, IconButton } from '@mui/material';
import { removeCartItem } from '../actions/cart';
import { CLEAR_CART } from '../constants/cartConstants';

const Cart = () => {
    const { cartItems } = useSelector((state) => state.cart);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let total, subTotal, deposit;
    const tax = 0.18;

    subTotal = cartItems?.reduce((total, ci) => {
        return total + Number(ci.pricePerWeek * ci.quantity * ci.weeks);
    }, 0);

    deposit = cartItems?.reduce((total, ci) => {
        return total + Number(ci.quantity * ci.deposit);
    }, 0);

    total = (1 + tax) * subTotal + deposit;

    const handleRemoveCartItem = (e, id) => {
        e.preventDefault();
        dispatch(removeCartItem(id));
    };

    const clearCart = () => {
        dispatch({ type: CLEAR_CART });
    };

    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, [dispatch]);

    const isMobile = width <= 700;

    return (
        <>
            {/* <!-- ======= Breadcrumbs Section ======= --> */}
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h1 className="breadcrumb-title">Cart</h1>
                        <ol>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>Cart</li>
                        </ol>
                    </div>
                </div>
            </section>
            {/* <!-- Breadcrumbs Section --> */}
            {/* <!-- Checkout Section Begin --> */}
            {/* <!-- Shopping Cart Section Begin --> */}
            <section className="shopping-cart spad">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="shopping__cart__table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Quantity</th>
                                            <th>Weeks</th>
                                            <th>Deposit</th>
                                            <th>Total</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!cartItems.length ? <h2>Nothing to see here</h2> : cartItems.map((p) => (
                                            <tr key={p.productId}>
                                                <td className="product__cart__item">
                                                    <div className="product__cart__item__pic">
                                                        <Link to={`/products/${p.productId}`}>
                                                            <img style={{ height: '75px', width: '113px' }} src={p.image} alt="" />
                                                        </Link>
                                                    </div>
                                                    <div className="product__cart__item__text">
                                                        <h6>{p.name}</h6>
                                                        <h5>Rs {p.pricePerWeek}</h5>
                                                    </div>
                                                </td>
                                                <td className="quantity__item">
                                                    <div className="quantity">
                                                        <div className="pro-qty-2" style={{ textAlign: 'center' }}>
                                                            <input style={{ color: '#333' }} type="text" value={p.quantity} readOnly />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="quantity__item">
                                                    <div className="quantity">
                                                        <div className="pro-qty-2">
                                                            <input style={{ color: '#333' }} type="text" value={p.weeks} readOnly />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="cart__price">Rs {p.quantity * p.deposit}</td>
                                                <td className="cart__price">{p.quantity * p.weeks * p.pricePerWeek + p.quantity * p.deposit}</td>
                                                <td className="cart__close">
                                                    <IconButton onClick={(e) => handleRemoveCartItem(e, p.productId)}>
                                                        <HighlightOffSharpIcon style={{ fontSize: "32px" }} color="error" />
                                                    </IconButton>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="continue__btn update__btn" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                        <Button onClick={() => clearCart()} variant='contained' style={{ backgroundColor: "black", borderRadius: '4px' }}>Clear Cart</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="cart__total" style={{ borderRadius: '4px', marginTop: `${isMobile ? '20' : '0'}px` }}>
                                <h6>Cart total</h6>
                                <ul>
                                    <li>Subtotal <span>Rs {subTotal}</span></li>
                                    <li>GST <span>{tax * 100}%</span></li>
                                    <li>Depost <span>Rs {deposit}</span></li>
                                    <li>Total <span>Rs {total}</span></li>
                                </ul>
                                <div style={{ width: '100%' }}>
                                    <Button onClick={() => navigate('/checkout')} variant='contained' style={{ marginLeft: '11%', border: "0 solid", borderRadius: '4px', backgroundColor: '#253f4b' }}>Proceed to checkout</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Shopping Cart Section End --> */}
        </>
    )
}

export default Cart;
