import { combineReducers } from "redux";
import auth from './auth.js'
import products from './products.js'
import cart from "./cart.js";
import category from "./category.js";
import order from "./order.js";
import {
    FLUSH, PAUSE,
    PERSIST, persistReducer, PURGE,
    REGISTER, REHYDRATE
  } from 'redux-persist';
  import storage from 'redux-persist/lib/storage';


export default combineReducers({
  auth:persistReducer(
    {
      key: 'auth',
      storage
    },
    auth
  ),
    products:products,
    category:category,
    cart:persistReducer(
        {
          key: 'cart',
          storage
        },
        cart
      ),
    order:order,
})