import { Button } from '@mui/material';
import React, { useEffect } from 'react'
import useRazorpay from "react-razorpay";
import { useDispatch, useSelector } from 'react-redux';
import { makePayment } from '../actions/order';
import { useNavigate } from 'react-router-dom';

const Payment = () => {

  const { options,billingFormDetails, orderDetails } = useSelector((state) => state.order)
  const {cartItems} = useSelector((state)=>state.cart)
  const dispatch = useDispatch()

  const Razorpay = useRazorpay();
  const navigate = useNavigate()
  const paymentSuccess = (ps) => {
      navigate(`/payment-success/${ps}`)
    }
  const handlePay = () => {
    const total = cartItems.reduce((total,ci)=>{return total+(ci.pricePerWeek*ci.quantity*ci.weeks)},0)
    const deposit = cartItems.reduce((total,ci)=>{return total+(ci.quantity*ci.deposit)},0)
    let orderIds = []

    for(const o of orderDetails)
      orderIds.push(o._id)
    // console.log(orderIds)

    dispatch(makePayment({...billingFormDetails, total, deposit},orderIds,paymentSuccess))

  



    
  }


  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button onClick={() => handlePay() }  variant = 'contained' color='success'  >
        Pay Now
      </Button >
    </div>
  )
}

export default Payment
