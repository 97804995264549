import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { emptyCart } from '../actions/cart'
import { emptyOrder } from '../actions/order'

const PaymentSuccess = () => {
    const {transactionId} = useParams()
    const dispatch = useDispatch()
    useEffect(()=>{
      dispatch(emptyCart())
      dispatch(emptyOrder())
    },[])
  return (
    <div style={{ backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '8px' }}>
    <h1 style={{ color: '#006fff', marginBottom: '10px' }}>Payment Successful!</h1>
    <p style={{ color: '#333', marginBottom: '10px' }}>Transaction ID: {transactionId}</p>
    <p style={{ color: '#333' }}>Thank you for your purchase. Your payment was successful.</p>
  </div>
  )
}

export default PaymentSuccess
