import {
    ADD_TO_CART,
    CLEAR_CART,
    EMPTY_CART,
    REMOVE_CART_ITEM,
    SAVE_SHIPPING_INFO,
  } from "../constants/cartConstants.js";
  
const cartReducer = (
    state = { cartItems: [], shippingInfo: {} },
    action
  ) => {
    switch (action.type) {
      case ADD_TO_CART:
        const item = action.payload;
  
        const isItemExist = state.cartItems.find(
          (p) => p.productId === item.productId
        );
        if (isItemExist) {
          // console.log(state)

          return {
            ...state,
            cartItems: state.cartItems.map((i) =>
              i.productId === isItemExist.productId ? item : i
            ),
          };
        } else {
          // console.log(state)
          return {
            ...state,
            cartItems: [...state.cartItems, item],
          };
        }
  
      case REMOVE_CART_ITEM:
        return {
          ...state,
          cartItems: state.cartItems.filter((i) => i.productId !== action.payload),
        };
      case CLEAR_CART:
        return {
          ...state,
          cartItems: [],
        };
  
      case SAVE_SHIPPING_INFO:
        return {
          ...state,
          shippingInfo: action.payload,
        };

        case EMPTY_CART:
          return { cartItems: [], shippingInfo: {} }
  
      default:
        return state;
    }
  };
  
export default cartReducer