import { Card, CardContent, CardMedia, CircularProgress, Container, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { getYourOrders } from '../actions/order'

const YourOrders = () => {

    const {yourOrders,isLoading} = useSelector((state)=>state.order)
    const dispatch = useDispatch()

    useEffect(()=>{
        !yourOrders.length && dispatch(getYourOrders())
        console.log("hello")
    },[])

    const cards = [1,2,3,4]
  return (
    isLoading? <CircularProgress/> : <Container style={{padding:'20px 20px'}} maxWidth> 
        <Grid container spacing={4}>

            {yourOrders.map((p)=>(
            <Grid item key={p._id} xs={12} sm={6} md={6} >


      <Card style={{height:"100%", display:"flex",flexDirection:"row"}}>
        <CardMedia 
            style={{ 
                flexGrow:'2',
                // padding:"56.25%",
                minHeight:'250px', 
                minWidth:"250px", 
                height:'100%', 
                maxWidth:"250px", 
                objectFit: 'cover',  // Ensures the image covers the entire space without cropping
                objectPosition: 'center',  // Centers the image within the CardMedia component
            }}
            image={p.productImage}
            title="Product"
        />
        <div style={{display:'flex',flexDirection:"column"}}>

        <CardContent style={{flexGrow:'3'}}>
            <Typography variant='h5' gutterBottom>
                {p.productName}
            </Typography>
            <Typography variant='subtitle1' color='textSecondary' sx={{
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 3,
    }}>
                {p.productDescription}
            </Typography>
            <Typography variant='body2' color='textPrimary'>
                Date Rented: {p.rentalDate?.substr(0,10)}
            </Typography>
            <Typography variant='body2' color='textPrimary'>
                Return Date: {p.plannedReturningDate?.substr(0,10)}
            </Typography>
            <Typography variant='body2' color='textPrimary'>
                Quantity: {p.quantity}
            </Typography>
            <Typography variant='body2' color='textPrimary'>
                Deposit: {p.deposit  }
            </Typography>
            <Typography variant='body1' color='textPrimary'>
                Amount: {+p.amount + +p.deposit}
            </Typography>
        </CardContent>
        </div>
      </Card>
            </Grid>))}
        </Grid>
    </Container>
  )
}

export default YourOrders
