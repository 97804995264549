import {
    ADD_TO_CART,
    EMPTY_CART,
    REMOVE_CART_ITEM,
    SAVE_SHIPPING_INFO,
  } from "../constants/cartConstants";
  
  // Add to Cart
  export const addItemsToCart = (product, weeks, quantity) => async (dispatch) => {
    // console.log(product)
    dispatch({
      type: ADD_TO_CART,
      payload: {
        productId: product._id,
        name: product.productName,
        pricePerMonth: product.costPerMonth,
        pricePerWeek: product.costPerWeek,
        image: product.productImages[0].url,
        stock: product.noOfStock,
        quantity,
        weeks,
        deposit:product.deposit,
        description:product.description,
        supplierId: product.supplierId
      },
    });
    // console.log('end')
  
    // localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
  };
  
  export const removeCartItem = (id) => async(dispatch) => {
    console.log("Hello")
    dispatch({
      type:REMOVE_CART_ITEM,
      payload: id
    })
  }
  export const emptyCart = (id) => async(dispatch) => {
    dispatch({
      type:EMPTY_CART
    })
  }