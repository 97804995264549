import React from 'react'
import { Link } from 'react-router-dom'

const BecomeButton = () => {
  return (
    <section className="product py-3 bg1">
        <div className="container">
            <div className="row mb-2">
                <div className="col-md-6">
                    <Link to="/signup">
                        <img src={process.env.PUBLIC_URL + "/img/slides/1.png"} style={{width:"100%"}}/>
                    </Link>
                </div>
                <div className="col-md-6">
                    <Link to="https://supplier.rentomart.com/">
                        <img src={process.env.PUBLIC_URL + "/img/slides/2.png"} style={{width:"100%"}}/>
                    </Link>
                </div>
            </div>
        </div>
    </section>
  )
}

export default BecomeButton
