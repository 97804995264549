import * as api from '../api'
import { START_LOADING } from '../constants/actionTypes';
import { EMPTY_ORDER, END_LOADING_ORDERS, ENTER_BILLING_DETAILS, ENTER_ORDER_DETAILS, GET_YOUR_ORDERS, OPTIONS, START_LOADING_ORDERS } from '../constants/orderConstants';



export const createOrder = (cart,setActiveStep) => async(dispatch) => {
    try{
        let order = []
       cart.forEach(c => {
        let date = new Date()
        let date2 = new Date()
        date.setDate(date.getDate() + 1)
        date2.setDate(date.getDate()+c.weeks*7)
        order.push({
            productId:c.productId,
            productName:c.name,
            amount:c.pricePerWeek*c.quantity*c.weeks,
            deposit:c.deposit*c.quantity,
            rentalDate:date,
            plannedReturningDate:date2,
            orderStatus:'unpaid',
            productImage: c.image,
            productDescription: c.description,
            quantity: c.quantity,
            supplierId: c.supplierId
        })
       });
    //    console.log(order)
    const {data} = await api.createOrder(order)
    // console.log(data.order)
    dispatch({type:ENTER_ORDER_DETAILS,payload:data.order});
    setActiveStep(2)
    // alert(data.data)
    }
    catch(err)
    {
        console.log(err);
        alert(err.response.data.message)
    }
}

export const getYourOrders = () => async(dispatch) => {
    dispatch({type:START_LOADING_ORDERS})
    const {data} = await api.getYourOrders()
    dispatch({type:GET_YOUR_ORDERS,payload:data.orders})
    dispatch({type:END_LOADING_ORDERS})

}

export const enterBillDetails = (billDetails)=> async(dispatch)  =>{
    dispatch({type:ENTER_BILLING_DETAILS,payload:billDetails});

}
// export const storePaymentDetails = (paymentDetails)=> async(dispatch)  =>{
//     await api.storePaymentDetails(paymentDetails)
// }

export const makePayment = (paymentData, rentOrderIds, paymentSuccess)=> async(dispatch) =>{
    try {

        const {data} = await api.makePayment(paymentData)
        let options = {
            key : data.key_id,
            amount: data.amount,
            currency:'INR',
            name: data.product_name,
            description: data.description,
            image: 'https://rentomart.com/img/logo.png',
            order_id: data.order_id,
            handler: (razorpayVerification) => {
                // dispatch(makeBill(paymentData, {razorpayVerification, order_id:data.order_id,orderDetails})) 
                dispatch(makeBill({rentOrderIds,order_id:data.order_id, amount : paymentData.total, deposit: paymentData.deposit, billingAddress:[paymentData.primaryAddress, paymentData.secondaryAddress],billingDate: new Date(),razorpayVerification}, paymentSuccess)) 
            },
            prefill:{
                contact:data.contact,
                name:data.name,
                email:data.email
            },
            notes: {
                description: data.description,
            },
            theme : {
                color: '#0b64bd'
            }
        }

        dispatch({type:OPTIONS,payload:options})


        let razorpayObject = new window.Razorpay(options)

    razorpayObject.on('payment.failed', (res) => alert('Payment Failed'));


    razorpayObject.open()



            
        
    } catch (err) {
        console.log(err)
        // alert("Hello")
        alert(err.response?.data?.message)
    }
}

export const makeBill = (billData, paymentSuccess)=> async(dispatch) =>{
    // console.log(billData)
    try {
        const {data} = await api.storePaymentDetails(billData)
        console.log(data.bill)
        alert(`Your payment Id is ${data.bill.razorpayVerification.razorpay_payment_id}`)
        paymentSuccess(data.bill.razorpayVerification.razorpay_payment_id)
        
        
        
    } catch (err) {
        console.log(err)
    }

}
export const emptyOrder = (billData, paymentSuccess)=> async(dispatch) =>{
    // console.log(billData)
    try {
        dispatch({type:EMPTY_ORDER})
        
        
        
    } catch (err) {
        console.log(err)
    }

}