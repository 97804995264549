import React from 'react'

const Banner = () => {
  return (
    <>
        <section className="hero">
            <div className="hero__items set-bg" alt="" ></div>
        </section>
    </>
  )
}

export default Banner
