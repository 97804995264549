import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import OrderForm from './OrderForm';
import ConfirmOrder from './ConfirmOrder';
import Payment from './Payment';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const steps = ['Enter Form Details', 'Confirm Details', 'Place the Order'];

const Checkout = () => {
  // const {authData} = useSelector((state)=>state.auth)
  const user = JSON.parse(localStorage.getItem('profile'))
  const navigate = useNavigate()
  useEffect(() => {
    if (!user || !user.result)
      navigate('/login')
  }, [])
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ width: '100%' }}>
      <div className='container mt-4'>

        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={index<activeStep ? handleStep(index) : ()=>{}}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>

      </div>
      <div>
        {allStepsCompleted() ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </>
        ) : (
          <>
            <Container >
              {activeStep === 0 && <OrderForm setActiveStep={setActiveStep} />}
              {(activeStep === 1 || activeStep=== 2) && <>  <ConfirmOrder setActiveStep={setActiveStep} activeStep={activeStep} /> {activeStep === 2 && <Payment/>} </>}
              {/* {activeStep === 2 && <> <ConfirmOrder setActiveStep={setActiveStep} activeStep={activeStep} /> <Payment /> </>} */}
            </Container>
            {

              activeStep !== 0 && <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'center' }}>
                <Button
                  // color=""
                  className='mb-4 text-white'
                  style={{ backgroundColor: 'black' }}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                  variant='contained'
                >
                  Back
                </Button>
                {/* <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography variant="caption" sx={{ display: 'inline-block' }}>
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Finish'
                      : 'Complete Step'}
                  </Button>
                ))} */}
              </Box>
            }
          </>
        )}
      </div>
    </Box>
  );
}

export default Checkout