import { Button, Container, Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import Cart from './Cart'
import { useDispatch, useSelector } from 'react-redux'
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import { Link, useNavigate } from 'react-router-dom'
import { removeCartItem } from '../actions/cart';
import { createOrder, makePayment } from '../actions/order';

const ConfirmOrder = ({setActiveStep , activeStep}) => {
    const user = JSON.parse(localStorage.getItem('profile'))
    const { cartItems } = useSelector((state) => state.cart)
    const {billingFormDetails, orderDetails} = useSelector((state)=>state.order)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const GST = 0.18
    let total = cartItems.reduce((total,ci)=>total+ci.quantity*ci.weeks*ci.pricePerWeek,0)
    total = total + total * GST


    const handleRemoveCartItem = (e, id) => {
        // console.log("Hhh")
        e.preventDefault()
        dispatch(removeCartItem(id))
    }
    
    const handleConfirmOrder = () => {
        dispatch(createOrder(cartItems, setActiveStep))
        // dispatch(makePayment({...billingFormDetails, total, useSelector}))
        // setActiveStep(2)
    }

    // useEffect(()=>{})


    return (
        <>
            <br />
            <section >
                <div className="container p-2">
                    <div style={{ textAlign: 'center' }}>
                        <h3>Order Details</h3>
                    </div>
                    
                    <Container className=" p-0 m-0" >
                        <Typography variant='subtitle1'>Name:{user.result.fullName}</Typography>
                        <Typography variant='subtitle1'>Phone:{user.result.mobileNumber}</Typography>
                        <Typography variant='subtitle1'>Primary Address:{billingFormDetails.primaryAddress} </Typography>
                        <Typography variant='subtitle1'>Secondary Address:{billingFormDetails.secondaryAddress} </Typography>
                        <Typography variant='subtitle1'>Pin Code:{billingFormDetails.pinCode} </Typography>
                    </Container>
                
                <div className="shopping__cart__table">
                    <div style={{ textAlign: 'center' }} >
                        <h3 >Cart Details</h3>
                    </div >
                    <table>
                            <thead>
                                <tr>
                                    <th>Product</th>
                                    <th>Quantity</th>
                                    <th>Weeks</th>
                                    <th>Deposit</th>
                                    <th>Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!cartItems.length? <h2>Nothing to see here</h2> : cartItems.map((p)=>(<tr>
                                    <td className="product__cart__item">
                                        <div className="product__cart__item__pic">
                                            <Link to={`/products/${p.productId}`}>
                                            <img style={{height:'75px',width:'113px'}} src={p.image} alt=""/>
                                            </Link>
                                        </div>
                                        <div className="product__cart__item__text">
                                            <h6>{p.name}</h6>
                                            {/* <h5>{p.pricePerMonth}</h5> */}
                                            <h5>Rs {p.pricePerWeek}</h5>
                                        </div>
                                    </td>
                                    <td className="quantity__item">
                                        <div className="quantity">
                                            <div className="pro-qty-2">
                                                
                                                <input style={{color:'#333'}} type="text" value={p.quantity}/>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="quantity__item">
                                        <div className="quantity">
                                            <div className="pro-qty-2">
                                                <input style={{color:'#333'}} type="text" value={p.weeks}/>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="cart__price">Rs {p.quantity * p.deposit}</td>
                                    <td className="cart__price">Rs {p.quantity * p.weeks * p.pricePerWeek + p.quantity * p.deposit}</td>
                                    <td className="cart__close"> <IconButton onClick={(e)=>handleRemoveCartItem(e,p.productId)} > <HighlightOffSharpIcon style={{fontSize:"32px"}} color="error" />  </IconButton>  </td>
                                </tr>))}
                            </tbody>
                        </table>
                </div>
                {

                    activeStep===1 && <div style={{display:'flex',justifyContent:'center'}}>
                    <Button onClick={()=>handleConfirmOrder()} variant='contained' > Confirm Order </Button>
                </div>
                }
                </div>
            </section>
        </>
    )
}

export default ConfirmOrder
