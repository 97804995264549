import { END_LOADING, START_LOADING } from "../constants/actionTypes";
import { EMPTY_ORDER, END_LOADING_ORDERS, ENTER_BILLING_DETAILS, ENTER_ORDER_DETAILS, GET_YOUR_ORDERS, OPTIONS, START_LOADING_ORDERS } from "../constants/orderConstants";


const user = JSON.parse(localStorage.getItem('profile'))


const initialState = {
    orderDetails: [{}],
    yourOrders: [],
    billingFormDetails: {
        primaryAddress: '',
        secondaryAddress: null,
        pinCode: '',
        mobileNumber: user?.result?.mobileNumber,
    },
    billingDetails:{},
    isLoading: false,
    options : {}
}


const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case ENTER_ORDER_DETAILS:
            return { ...state, orderDetails: action.payload };
        case ENTER_BILLING_DETAILS:
            return { ...state, billingFormDetails: action.payload };
        case GET_YOUR_ORDERS:
            return { ...state, yourOrders: action.payload };
        case OPTIONS:
            // console.log(action.payload)
            return { ...state, options: action.payload };
        case START_LOADING_ORDERS:
            return { ...state, isLoading: true }
        case END_LOADING_ORDERS:
            return { ...state, isLoading: false }
        case EMPTY_ORDER:
            return initialState
        default:
            return state;
    }
}

export default orderReducer