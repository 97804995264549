import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getCategories } from '../actions/product'

const RentalCategories = () => {
    const {categories,countC} = useSelector((state)=>state.category)
    const dispatch = useDispatch()
    
    const [width, setWidth] = useState(window.innerWidth);
    
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        !categories.length && dispatch(getCategories())
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    const isMobile = width <= 700;

  return (
    <>
    <section className="product spad py-3 bg2" id="service">
    <div className="container">
        <h2 className="section-title text-white">Rental Cateogries</h2>
        <div className="row product__filter justify-content-center">
            
            { categories?.map((c)=>(

                <div className="" style={isMobile?{width:'30%',marginRight:'1.5%',marginLeft:'1.5%'}:{width:`${(100/(countC+1))}%`,marginRight:`${100/((countC+1)*(countC)*2)}%`,marginLeft:`${100/((countC+1)*(countC)*2)}%`}} key={c._id}>
                <div className="card mb-3" style={{height:'90%'}} >
                    <Link to={`/products/search?category=${c.categoryName}`}>
                    <img src={c.categoryImage.url} alt=""/>
                    <h5 className="text-center my-cateogries">{c.categoryName}</h5></Link>
                </div>                    
            </div>
                ))}
            {/* <div className="col-lg-2 col-md-4 col-6">
                <div className="card mb-3">
                    <Link to="/products/search?category=Jewellery">
                    <img src={process.env.PUBLIC_URL + "/img/vector/jewellery.jpg" }alt=""/>
                        <h5 className="text-center my-cateogries">Jewellery</h5></Link>
                </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
                <div className="card mb-3">
                    <Link to="/products/search?category=Outfit">
                    <img src={process.env.PUBLIC_URL + "/img/vector/outfit.jpg" }alt=""/>
                    <h5 className="text-center my-cateogries">Outfit</h5></Link>
                </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
                <div className="card mb-3">
                    <Link to="/products/search?category=Home Appliance">
                    <img src={process.env.PUBLIC_URL + "/img/vector/home-appliance.jpg"} alt=""/>
                    <h5 className="text-center my-cateogries">Home Appliance</h5></Link>
                </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6">
                <div className="card mb-3">
                    <Link to="/products/search?category=Furniture">
                    <img src={process.env.PUBLIC_URL + "/img/vector/furniture.jpg" }alt=""/>
                    <h5 className="text-center my-cateogries">Furniture</h5></Link>
                </div>                    
            </div>
            <div className="col-lg-2 col-md-4 col-6">
                <div className="card mb-3">
                    <Link to="/products/search?category=Electrical Machinery">
                    <img src={process.env.PUBLIC_URL + "/img/vector/electrical-machinery.jpg"} alt=""/>
                    <h5 className="text-center my-cateogries">Electrical/Machinery</h5></Link>
                </div>
            </div> */}
        </div>
    </div>
</section>
    </>
  )
}

export default RentalCategories
