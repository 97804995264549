import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from 'react-router-dom';
import decode from 'jwt-decode'
import SearchBar from './SearchBar';
import { getCategories, getSubCategories } from '../actions/product';
// import "./js/main"


const Header = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const { subCategories, categories } = useSelector((state) => state.category)

    // const [clicked, setClicked] = useState(false)
    const [cop, setCop] = useState("")
    const handleClick = (e) => {
        e?.preventDefault()
        cop === "active" ? setCop("") : setCop("active")
    }

    const handleLogout = (e) => {
        // console.log(e)
        e?.preventDefault()
        setUser(null)
        dispatch({ type: "LOGOUT" })
        navigate('/')
    }

    useEffect(() => {
        const token = user?.token;

        // Taking care of a jwt token's expiry here
        if (token) {
            const decodedToken = decode(token)
            // console.log(decode(token))
            if (decodedToken.exp * 1000 < new Date().getTime())
                handleLogout()
        }


        setUser(JSON.parse(localStorage.getItem('profile')));
        !categories.length && dispatch(getCategories())
        !subCategories.length && dispatch(getSubCategories())
    }, [location])

    const [search, setSearch] = useState("")



    return (
        <>
            <header>

                {/* <!-- Offcanvas Menu Begin --> */}
                <div onClick={handleClick} className={`offcanvas-menu-overlay ${cop}`}></div>
                <div className={`offcanvas-menu-wrapper ${cop}`}>
                    <div className="offcanvas__option">
                        {user?<div className="offcanvas__links">
                            <Link to="#" onClick={handleLogout}>Logout</Link>
                        </div>:<div className="offcanvas__links">
                            <Link to="/signup">Sign up</Link>
                            <Link to="/login">Log in</Link>
                        </div>}
                    </div>
                    <div id="mobile-menu-wrap" className="mobile-menu">
                        <ul className="dropdown" style={{ listStyleType: "none", margin: "0px", padding: "0px", border: "0px", display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                            <li>
                                <Link className="list-group-item list-group-item-action" onClick={(e)=>{handleClick(e);navigate('/categories')}} style={{ borderColor: "#FFF", paddingLeft:'0' }} to="/categories">Categories</Link>
                            </li>
                            <li>
                                <div>
                                <form className="d-flex" onSubmit={(e) => { e.preventDefault(); navigate(`/products/search?searchQuery=${search || 'none'}`) }}>
                                    <input className="form-control me-2" type="text" placeholder="Search" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                                    <button className="btn" type="submit" style={{ backgroundColor: "#0B64BD", color:'white' }}>Search</button>
                                </form>
                                </div>
                            </li>
                            <li>
                                <Link className="list-group-item list-group-item-action" onClick = {(e)=>{handleClick(e); navigate("/products")}} style={{ borderColor: "#FFF", paddingLeft:'0' }} to="/products">Products</Link>
                            </li>
                            <li>
                                <Link className="list-group-item list-group-item-action" onClick = {(e)=>{handleClick(e); navigate("/profile")}} style={{ borderColor: "#FFF", paddingLeft:'0' }} to="/profile">Profile</Link>
                            </li>
                            <li>
                                <Link className="list-group-item list-group-item-action" onClick = {(e)=>{handleClick(e); navigate("/cart")}} style={{ borderColor: "#FFF", paddingLeft:'0' }} to="/cart">
                                    Cart &nbsp; <span className="bi bi-cart-fill"></span>
                                </Link>
                            </li>
                            <li>
                                <Link className="list-group-item list-group-item-action" onClick = {(e)=>{handleClick(e); navigate("/profile")}} style={{ borderColor: "#FFF", paddingLeft:'0' }} to="/profile">Account</Link>
                            </li>
                            <li>
                                <Link className="list-group-item list-group-item-action" onClick = {(e)=>{handleClick(e); navigate("/your-orders")}} style={{ borderColor: "#FFF", paddingLeft:'0' }} to="/your-orders">My Orders</Link>
                            </li>
                            <li>
                                <Link className="list-group-item list-group-item-action" onClick = {(e)=>{handleClick(e); navigate("/contact-us")}} style={{ borderColor: "#FFF", paddingLeft:'0' }} to="/contact-us">Contact Us</Link>
                            </li>
                            <li>
                                <Link className="list-group-item list-group-item-action" onClick = {(e)=>{handleClick(e); navigate("/about-us")}} style={{ borderColor: "#FFF", paddingLeft:'0' }} to="/about-us">About Us</Link>
                            </li>
                            {user && (
                                <li>
                                    <Link className="list-group-item list-group-item-action" onClick = {(e)=>{handleClick(e); navigate("#")}} style={{ borderColor: "#FFF", paddingLeft:'0' }} to="#">My Account</Link>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
                {/* <!-- Offcanvas Menu End --> */}

                {/* <!-- Header Section Begin --> */}
                <header className="header">
                    <div className="header__top">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-7">
                                    <div className='header__top__left'>

                                    <ul className="text-white">
                                        <li className="fa fa-whatsapp header-social"></li>
                                        <li className="fa fa-facebook header-social"></li>
                                        <li className="fa fa-twitter header-social"></li>
                                        <li className="fa fa-instagram header-social"></li>
                                    </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-5">
                                    <div className="header__top__right">
                                        {
                                            user ? (<div className="header__top__links" >
                                                <Link to="#" onClick={handleLogout}>Logout</Link>
                                            </div>) : (<div className="header__top__links">
                                                <Link to="/signup">Sign up</Link>
                                                <Link to="/login">Log in</Link>
                                            </div>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="header-nav">
                        <div className="row">
                            <div className="col-lg-2 col-md-2">
                                <div className="header__logo" style={{width:'140%'}}>
                                    <Link to="/"><img src={process.env.PUBLIC_URL + "/img/logo.png"} alt="Hello" style={{ height: '72px' }} /></Link>
                                </div>
                            </div>
                            <div className="col-lg-10 col-md-10">
                                <nav className="header__menu mobile-menu" style={{ float: 'left' }}>
                                    <ul>
                                        <li><Link to="#">Categories</Link>
                                            <ul className='dropdown'>
                                                {
                                                    categories?.map((c)=>(
                                                        <li>
                                                            <Link to ={`/products/search?category=${c.categoryName}`}>{c.categoryName}</Link>
                                                            <ul className='dropdown-submenu'>
                                                                {subCategories?.map((sc)=>(sc.categoryName === c.categoryName) && (
                                                                    <li> <Link to={`/products/search?subCategory=${sc.subCategoryName}`}> {sc.subCategoryName} </Link> </li>
                                                                ))}
                                                            </ul>
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </li>
                                        <li>

                                            <SearchBar />
                                        </li>
                                        <li>
                                            <Link onClick={()=>navigate("https://supplier.rentomart.in/")} to="https://supplier.rentomart.in/">Become a Supplier</Link>
                                        </li>
                                        <li>
                                            <Link to="/products">Products</Link>
                                        </li>
                                        <li>
                                            <Link to="/cart">
                                                Cart
                                                &nbsp;
                                                <span className="bi bi-cart-fill"></span>
                                            </Link>
                                        </li>
                                        {user && (<li>
                                            <li><Link to="#">My Account</Link>
                                                <ul className='dropdown'>
                                                    <li><Link to="/profile">Profile</Link></li>
                                                    <li><Link to="/your-orders">Your Orders</Link></li>
                                                    <li><Link to="#">Your Wish List</Link></li>
                                                    <li><Link to="#">Your Address</Link></li>
                                                    <li><Link to="#">Liked Items</Link></li>
                                                </ul>
                                            </li>
                                        </li>)}

                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="canvas__open" onClick={handleClick}><i className="fa fa-bars"></i></div>
                    </div>
                </header>
                {/* <!-- Header Section End --> */}
            </header>
        </>
    )
}

export default Header
