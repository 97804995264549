import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
        <footer>
     <footer className="footer">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="footer__about">
                        <div className="footer__logo">
                            <Link to="#"><img src={process.env.PUBLIC_URL + "/img/rentomart-logo.png"} alt=""/></Link>
                        </div>
                        <p>
                            Unlock endless possibilities with our rental platform. 
                            Find what you need, when you need it, and at a price that works for you.
                        </p>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 justify-content-center">
                    <div className="footer__widget">
                        <h4>Useful Links</h4>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/#about">About Us</Link></li>
                            <li><Link to="/#service">Rental Categories</Link></li>
                            <li><Link to="/#blogs">Blogs</Link></li>
                            <li><Link to="/#contact">Contact Us</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6">
                    <div className="footer__widget">
                        <h4>Rental Categories</h4>
                        
                          <ul>
                            <li><Link to="#">Smart Devices</Link></li>
                            <li><Link to="#">Jewellery</Link></li>
                            <li><Link to="#">Outfits</Link></li>
                            <li><Link to="#">Home Appliance</Link></li>
                          
                            <li><Link to="#">Furniture</Link></li>
                            <li><Link to="#">Electrical/Machinery</Link></li>
                          </ul>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="footer__widget">
                        <h4>Policies</h4>
                        <ul>
                            <li><Link to="#">Shipping Policy</Link></li>
                            <li><Link to="#">Cancellation & Return Policy</Link></li>
                            <li><Link to="#">Privacy policy</Link></li>
                            <li><Link to="#">Rental Terms & Conditions</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    <div className="search-model">
        <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="search-close-switch">+</div>
            <form className="search-model-form">
                <input style={{color:'#333'}} type="text" id="search-input" placeholder="Search here....."/>
            </form>
        </div>
    </div>
</footer>
    </>
  )
}

export default Footer
