import React, { useEffect, useState } from 'react'
import FileBase from 'react-file-base64';


const KycForm = () => {

    const initailState = {
        kycId:"",
        panCard:"",
        panCardNumber:"",
        aadharCard:"",
        aadharCardNumber:"",
        selfie:"",
        occupation:"",
        companyName:"",
        companyMailId:"",
        gstNumber:"",
        bankStatement:"",
        collegeName:"",
        parentsBankStatement:"",
        parentsAadhar:"",
        kycType:"",
        kycStatus:""
  }
    
    const user = JSON.parse(localStorage.getItem('profile'))
  
    const [data, setData] = useState(initailState)
  
    const handleChange = (e) =>{
      if(e.target?.accept==="image/*")
      {
        console.log(e.target.files[0])
        if(e.target.files[0].type.startsWith("image"))
          setData({...data, [e.target.name]:e.target.files[0]})
        else
          alert(`Please select an image file for ${e.target.name}`)
          // console.log(e)
      }  
      else
        setData({...data, [e.target.name]:e.target.value})
    }
    const handleSubmit = (e) =>{
      e.preventDefault()
      console.log(data)
    }
    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    const isMobile = width <= 700;
    return (
      <>
          <br/>
         <section >
             <div className="container">
                 <div className="sign-form px-auto" style={isMobile?{border:'none',marginLeft:'7%',marginRight:'7%',width:'50vw'}:{}} >
                     <h2 style={isMobile?{marginLeft:'50%'}:{}} >KYC</h2>
                     <form onSubmit={handleSubmit} style={isMobile ? { width: "150%" } : {}} >
                          <div className="form-row" >
                            <div className=" col-lg-6">
                              <input  onChange={handleChange} type="text" className="form-control" name="kycId" id="kycId" placeholder="kycId"/>
                            </div>
                            <div className=" col-lg-6">
                              <input  onChange={handleChange} type="text" className="form-control" name="occupation" id="occupation" placeholder="Occupation"/>
                            </div>
                          </div>
                          <div class>
                            <input  onChange={handleChange} type="text" className="form-control" name="companyName" id="companyName" placeholder="Company Name"/>
                          </div>
                          <div class>
                            <input  onChange={handleChange} type="text" className="form-control" name="companyMailId" id="companyMailId" placeholder="Company Mail Id"/>
                          </div>
                          <div className="form-row mb-0" >
                            <div className=" col-lg-6">
                              <input  onChange={handleChange} type="text" className="form-control" name="gstNumber" id="gstNumber" placeholder='GST Number'/>
                            </div>
                            <div className=" col-lg-6">
                              <input  onChange={handleChange} type="text" className="form-control" name="collegeName" id="collegeName" placeholder='College Name'/>
                            </div>
                            <div className=" col-lg-12">
                              <input  onChange={handleChange} type="text" className="form-control" name="kycType" id="kycType" placeholder='KYC Type'/>
                            </div>
                            <div className=" col-lg-12">
                              <input  onChange={handleChange} type="text" className="form-control" name="kycStatus" id="kycStatus" placeholder='KYC Status'/>
                            </div>
                            <div className="col-lg-12">
                              <input  onChange={handleChange} type="text" className="form-control" name="aadharCardNumber" id="aadharCardNumber" placeholder='Aadhar Number'/>
                            </div>
                            <label className="col-sm-3 col-form-label">Aadhar Card</label>
                            <input  onChange={handleChange} className="col-sm-3" type="file" accept='image/*' name="aadharCard" id="aadharCard" style={{borderLeftWidth: "0px",borderTopWidth: "0px",borderRightWidth: "0px",borderBottomWidth: "0px",marginBottom: "0px"}}/>
                            <label className="col-sm-3 col-form-label">Parents Aadhar Card</label>
                            <input  onChange={handleChange}  className="col-sm-3" name="parentsAadhar" id="parentsAadhar" type="file" accept='image/*' style={{ borderLeftWidth: "0px",borderTopWidth: "0px",borderRightWidth: "0px",borderBottomWidth: "0px",marginBottom: "0px"}}/>
                            <div className="col-lg-12">
                              <input  onChange={handleChange} type="text" className="form-control" name="panCardNumber" id="panCardNumber" placeholder='Pan Card Number'/>
                            </div>
                            <label className="col-sm-3 col-form-label">Pan Card</label>
                            <input  onChange={handleChange} className="col-sm-9" name="panCard" id="panCard" type="file" accept='image/*' style={{borderLeftWidth: "0px",borderTopWidth: "0px",borderRightWidth: "0px",borderBottomWidth: "0px",marginBottom: "0px"}}/>
                            <label className="col-sm-3 col-form-label">Bank Statement</label>
                            <input  onChange={handleChange} className="col-sm-9" type="file" accept='image/*' name="bankStatement" id="bankStatement" style={{borderLeftWidth: "0px",borderTopWidth: "0px",borderRightWidth: "0px",borderBottomWidth: "0px",marginBottom: "0px"}}/>
                            <label className="col-sm-3 col-form-label">Parents Bank Statement</label>
                            <input  onChange={handleChange} className="col-sm-9" type="file" accept='image/*' name="parentsBankStatement" id="parentsBankStatement" style={{borderLeftWidth: "0px",borderTopWidth: "0px",borderRightWidth: "0px",borderBottomWidth: "0px",marginBottom: "0px"}}/>
                            <label className="col-sm-3 col-form-label">Selfie</label>
                            <input  onChange={handleChange} className="col-sm-9" type="file" accept='image/*' name="selfie" id="selfie" style={{borderLeftWidth: "0px",borderTopWidth: "0px",borderRightWidth: "0px",borderBottomWidth: "0px",marginBottom: "0px"}}/>
                            </div>
                            <button type="submit" className="btn btn-primary">
                              Submit  
                            {/* &nbsp; {isLoading && <CircularProgress style={{position:'absolute',color:'white'}}  size={25} disableShrink />}  */}
                            </button>
                        </form>
                 </div>   
             </div>
         </section>
         </>
    )
  }

export default KycForm
