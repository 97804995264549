import axios from 'axios';

const API = axios.create({baseURL: 'https://server.rentomart.in/'})

API.interceptors.request.use((req)=>{
    if(localStorage.getItem('profile'))
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`

        return req;
})
// this function will run like a middleware for other requests


export const logIn = (formData) => API.post('/user/login',formData)
export const signUp = (formData) => API.post('/user/signup',formData)
export const sendOTP = (authData) => API.get(`/user/${authData.result._id}/sendOTP`)
export const verify = (code, authData) => API.get(`/user/${authData.result._id}/verify/${code}`)
export const adminSignUp = (formData) => API.post('/user/adminSignup',formData)
export const adminVerify = (code, authData) => API.get(`/user/${authData.result._id}/adminVerify/${code}`)
export const adminLogIn = (formData) => API.post('/user/adminLogin',formData)

// Products

export const getProduct = (id) => API.get(`/product/${id}`);
export const getProducts = () => API.get(`/product/get-products`);
export const getMostlyRentedProducts = () => API.get(`/product/get-mostly-rented-products`);
export const getProductNames = () => API.get(`/product/product-names`);
export const getProductsBySearch = (searchQuery) => API.get(`/product/search?searchQuery=${searchQuery.search || 'none'}&category=${searchQuery.category}&subCategory=${searchQuery.subCategory}`);
export const getCategories = () => API.get(`/product/categories`);
export const getSubCategories = () => API.get(`/product/sub-categories`);

//Order
export const createOrder = (data) => API.post(`/order/post/order`,data);
export const getYourOrders = () => API.get(`/order/get/order`);
export const makePayment = (data) => API.post(`/order/post/payment`,data);
export const storePaymentDetails = (data) => API.post('/order/store/payment',data)
