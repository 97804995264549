export const AUTH='AUTH';
export const AUTH_ADMIN='AUTH_ADMIN';
export const LOGOUT='LOGOUT';
export const OTP='OTP'
export const SIGNUP='SIGNUP'
export const GET_PRODUCTS='GET_PRODUCTS'
export const GET_MOSTLY_RENTED_PRODUCTS='GET_MOSTLY_RENTED_PRODUCTS'
export const GET_PRODUCT='GET_PRODUCT'
export const GET_BY_SEARCH='GET_BY_SEARCH'
export const GET_PRODUCT_NAMES='GET_PRODUCT_NAMES'
export const END_LOADING='END_LOADING'
export const START_LOADING='START_LOADING'
export const END_LOADING_AUTH='END_LOADING_AUTH'
export const START_LOADING_AUTH='START_LOADING_AUTH'
export const FETCH_CATEGORIES = "FETCH_CATEGORIES"
export const FETCH_SUB_CATEGORIES = "FETCH_SUB_CATEGORIES"
