import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../actions/product';
import { CircularProgress, TextField, Button, Slider, Drawer, IconButton } from '@mui/material';
import { useSearchParams, Link } from 'react-router-dom';
import { FilterList } from '@mui/icons-material';
import './Products.css';

const Products = () => {
  const dispatch = useDispatch();
  const { isLoading, products } = useSelector((state) => state.products);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 10000]);
  const [sortOption, setSortOption] = useState('');
  const [productsState, setProductsState] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 700;

  useEffect(() => {
    const searchQuery = searchParams.get('searchQuery');
    const brands = searchParams.get('brands')?.split(',').filter(Boolean) || [];
    const minPrice = searchParams.get('minPrice') || 0;
    const maxPrice = searchParams.get('maxPrice') || 100000;

    setSelectedBrands(brands);
    setPriceRange([Number(minPrice), Number(maxPrice)]);

    let filteredProducts = products;

    if (searchQuery) 
      filteredProducts = filteredProducts.filter(product =>
        product.productName.toLowerCase().includes(searchQuery.toLowerCase())
    );

      if (brands.length > 0) {
        filteredProducts = filteredProducts.filter(product =>
          brands.includes(product.brand)
        );
      }

      filteredProducts = filteredProducts.filter(product => {
        const price = Number(product.costPerMonth);
        return price >= Number(minPrice) && price <= Number(maxPrice);
      });

      switch (sortOption) {
        case 'priceLowHigh':
          filteredProducts.sort((a, b) => a.costPerMonth - b.costPerMonth);
          break;
        case 'priceHighLow':
          filteredProducts.sort((a, b) => b.costPerMonth - a.costPerMonth);
          break;
        case 'mostPurchased':
          filteredProducts.sort((a, b) => b.purchases - a.purchases);
          break;
        case 'recentlyArrived':
          filteredProducts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          break;
        default:
          break;
      }
    

    setProductsState(filteredProducts);
  }, [searchParams, products, sortOption]);

  const handleBrandChange = (event) => {
    const { value } = event.target;
    setSelectedBrands((prev) =>
      prev.includes(value) ? prev.filter((brand) => brand !== value) : [...prev, value]
    );
  };

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPriceRange((prev) => (name === 'min' ? [Number(value), prev[1]] : [prev[0], Number(value)]));
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value === sortOption ? '' : event.target.value);
  };

  const applyFilters = () => {
    const params = {
      searchQuery: searchParams.get('searchQuery') || '',
      brands: selectedBrands.join(','),
      minPrice: priceRange[0],
      maxPrice: priceRange[1],
    };

    setSearchParams(params);
    setIsDrawerOpen(false);
  };

  const uniqueBrands = [...new Set(products.map((product) => product.brand))].sort();

  return (
    <>
      <section className="product spad py-3">
        <div className="container" style={{ maxWidth: '100vw' }}>
          {isMobile && (
            <div onClick={() => setIsDrawerOpen(true)} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px' }}>
              <IconButton>
                <FilterList />
              </IconButton>
              <span style={{ marginLeft: '10px' }}>Sort & Filter</span>
            </div>
          )}
          <div className="row" style={{ display: 'flex' }}>
            {!isMobile && (
              <div className="col-lg-3" style={{ flex: '0 0 20%' }}>
                <div className="sidebar">
                  <div className="filter-options">
                    <h5 style={{ fontSize: '1rem' }}>Sort By</h5>
                    <div className="form-check">
                      <input
                        className="form-check-input custom-checkbox"
                        type="checkbox"
                        id="priceLowHigh"
                        value="priceLowHigh"
                        checked={sortOption === 'priceLowHigh'}
                        onChange={handleSortChange}
                      />
                      <label className="form-check-label" htmlFor="priceLowHigh">
                        Price: Low to High
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input custom-checkbox"
                        type="checkbox"
                        id="priceHighLow"
                        value="priceHighLow"
                        checked={sortOption === 'priceHighLow'}
                        onChange={handleSortChange}
                      />
                      <label className="form-check-label" htmlFor="priceHighLow">
                        Price: High to Low
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input custom-checkbox"
                        type="checkbox"
                        id="mostPurchased"
                        value="mostPurchased"
                        checked={sortOption === 'mostPurchased'}
                        onChange={handleSortChange}
                      />
                      <label className="form-check-label" htmlFor="mostPurchased">
                        Most Purchased
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input custom-checkbox"
                        type="checkbox"
                        id="recentlyArrived"
                        value="recentlyArrived"
                        checked={sortOption === 'recentlyArrived'}
                        onChange={handleSortChange}
                      />
                      <label className="form-check-label" htmlFor="recentlyArrived">
                        Recently Arrived
                      </label>
                    </div>
                    <h5 style={{ fontSize: '1rem', marginTop: '1rem' }}>Filter By Brand</h5>
                    {uniqueBrands.map((brand) => (
                      <div className="form-check" key={brand}>
                        <input
                          className="form-check-input custom-checkbox"
                          type="checkbox"
                          id={`brand-${brand}`}
                          value={brand}
                          checked={selectedBrands.includes(brand)}
                          onChange={handleBrandChange}
                        />
                        <label className="form-check-label" htmlFor={`brand-${brand}`}>
                          {brand}
                        </label>
                      </div>
                    ))}
                    <h5 style={{ fontSize: '1rem', marginTop: '1rem' }}>Filter By Price</h5>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                      <TextField
                        label="Min Price"
                        type="number"
                        size="small"
                        name="min"
                        value={priceRange[0]}
                        onChange={handleInputChange}
                        style={{ width: '50%' }}
                        inputProps={{ min: 0, max: priceRange[1], style: { fontSize: '0.8rem' } }}
                      />
                      <TextField
                        label="Max Price"
                        type="number"
                        size="small"
                        name="max"
                        value={priceRange[1]}
                        onChange={handleInputChange}
                        style={{ width: '50%' }}
                        inputProps={{ min: priceRange[0], max: 10000, style: { fontSize: '0.8rem' } }}
                      />
                    </div>
                    <Slider
                      value={priceRange}
                      onChange={handlePriceChange}
                      valueLabelDisplay="auto"
                      min={0}
                      max={10000}
                      style={{ marginTop: '10px', maxWidth: '90%', marginLeft: '5%' }}
                      sx={{ color: '#0B64BD' }} // Custom slider color
                    />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={applyFilters}
                        style={{ marginTop: '10px', fontSize: '0.8rem', padding: '5px', backgroundColor: '#0B64BD' }} // Custom button color
                      >
                        Apply filter
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className={isMobile ? "col-12" : "col-lg-9"} style={{ flex: '1' }}>
              <div className="product__filter">
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  productsState?.map((p) => (
                    <div
                      key={p._id}
                      className="product__item"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: isMobile ? '20px 0px' : '20px',
                        margin: '10px 0',
                        width: '100%',
                        alignItems: isMobile ? 'flex-start' : 'center'
                      }}
                    >
                      <img
                        style={{ width: isMobile ? '120px' : '200px', height: isMobile ? '120px' : '200px', objectFit: 'fill', borderRadius: '5px' }}
                        src={p?.productImages[0]?.url}
                        alt=""
                      />
                      <div style={{ flex: '1', marginLeft: '20px' }}>
                        <h6>{p.productName}</h6>
                        <p>{p.brand}</p>
                        <p style={{ fontSize: '1.2rem' }}>Price: Rs {p.costPerMonth}</p>
                        <p>{p.description.slice(0, 100)}...</p>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{ backgroundColor: '#e74c3c' }}
                          component={Link}
                          to={`/products/${p._id}`}
                        >
                          View More
                        </Button>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Drawer anchor="bottom" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <div className="sidebar" style={{ padding: '20px' }}>
          <div className="filter-options">
            <h5 style={{ fontSize: '1rem' }}>Sort By</h5>
            <div className="form-check">
              <input
                className="form-check-input custom-checkbox"
                type="checkbox"
                id="priceLowHigh"
                value="priceLowHigh"
                checked={sortOption === 'priceLowHigh'}
                onChange={handleSortChange}
              />
              <label className="form-check-label" htmlFor="priceLowHigh">
                Price: Low to High
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input custom-checkbox"
                type="checkbox"
                id="priceHighLow"
                value="priceHighLow"
                checked={sortOption === 'priceHighLow'}
                onChange={handleSortChange}
              />
              <label className="form-check-label" htmlFor="priceHighLow">
                Price: High to Low
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input custom-checkbox"
                type="checkbox"
                id="mostPurchased"
                value="mostPurchased"
                checked={sortOption === 'mostPurchased'}
                onChange={handleSortChange}
              />
              <label className="form-check-label" htmlFor="mostPurchased">
                Most Purchased
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input custom-checkbox"
                type="checkbox"
                id="recentlyArrived"
                value="recentlyArrived"
                checked={sortOption === 'recentlyArrived'}
                onChange={handleSortChange}
              />
              <label className="form-check-label" htmlFor="recentlyArrived">
                Recently Arrived
              </label>
            </div>
            <h5 style={{ fontSize: '1rem', marginTop: '1rem' }}>Filter By Brand</h5>
            {uniqueBrands.map((brand) => (
              <div className="form-check" key={brand}>
                <input
                  className="form-check-input custom-checkbox"
                  type="checkbox"
                  id={`brand-${brand}`}
                  value={brand}
                  checked={selectedBrands.includes(brand)}
                  onChange={handleBrandChange}
                />
                <label className="form-check-label" htmlFor={`brand-${brand}`}>
                  {brand}
                </label>
              </div>
            ))}
            <h5 style={{ fontSize: '1rem', marginTop: '1rem' }}>Filter By Price</h5>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
              <TextField
                label="Min Price"
                type="number"
                size="small"
                name="min"
                value={priceRange[0]}
                onChange={handleInputChange}
                style={{ width: '50%' }}
                inputProps={{ min: 0, max: priceRange[1], style: { fontSize: '0.8rem' } }}
              />
              <TextField
                label="Max Price"
                type="number"
                size="small"
                name="max"
                value={priceRange[1]}
                onChange={handleInputChange}
                style={{ width: '50%' }}
                inputProps={{ min: priceRange[0], max: 10000, style: { fontSize: '0.8rem' } }}
              />
            </div>
            <Slider
              value={priceRange}
              onChange={handlePriceChange}
              valueLabelDisplay="auto"
              min={0}
              max={10000}
              style={{ marginTop: '10px', maxWidth: '90%', marginLeft: '5%' }}
              sx={{ color: '#0B64BD' }} // Custom slider color
            />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={applyFilters}
                style={{ marginTop: '10px', fontSize: '0.8rem', padding: '5px', backgroundColor: '#0B64BD' }} // Custom button color
              >
                Apply filter
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default Products;
