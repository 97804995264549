import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getProduct } from '../actions/product';
import { Button, CircularProgress, Paper } from '@mui/material';
import { addItemsToCart } from '../actions/cart';
import Carousel from "react-material-ui-carousel";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const SingleProduct = () => {
  const { productId } = useParams();
  const [weeks, setWeeks] = useState(1);
  const [quantity, setQuantity] = useState(1);

  const dispatch = useDispatch();
  const { isLoading, product } = useSelector((state) => state.products);

  useEffect(() => {
    (!product || product?._id !== productId) && dispatch(getProduct(productId));
  }, [productId]);

  const handleCart = () => {
    dispatch(addItemsToCart(product, +weeks > 0 ? weeks : 1, +quantity > 0 ? quantity : 1));
    setOpen(true);
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 700;

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {/* <!-- ======= Breadcrumbs Section ======= --> */}
          <section className="breadcrumbs">
            <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="breadcrumb-title">{product.productName}</h1>
                <ol>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>{product.productName}</li>
                </ol>
              </div>
            </div>
          </section>
          {/* <!-- Breadcrumbs Section --> */}

          {/* <!-- Shop Details Section Begin --> */}
          <section className="shop-details">
            <div className="product__details__content">
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div className={`col-md-6 py-4 ${isMobile ? 'text-center' : ''}`}>
                    <Carousel>
                      {product.productImages &&
                        product.productImages.map((item, i) => (
                          <img
                            style={{ width: '100%' }}
                            key={i}
                            src={item.url}
                            alt={`${i} Slide`}
                          />
                        ))}
                    </Carousel>
                  </div>
                  <div className="col-md-6 py-4">
                    <div className="product__details__text">
                      <h2>{product.productName}</h2>

                      <div className="rating">
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star"></i>
                        <i className="fa fa-star-o"></i>
                        <span> - 5 Reviews</span>
                      </div>

                      <h5>Weekly Price: Rs {product.costPerWeek}</h5>
                      <h5>Deposit: Rs {product.deposit}</h5>

                      <p>{product.description}</p>
                      <div className="product__details__cart__option" style={isMobile ? {display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}:{}} >
                        <div>
                        <label style={{ fontSize: '22px' }}>No. of Weeks :&nbsp;</label>
                        <div className="quantity">
                          <div className="pro-qty" >
                            <input style={{ color: '#333' }} type="number" min={1} onChange={(e) => setWeeks(e.target.value)} value={weeks} />
                          </div>
                        </div>
                        </div>
                        <div>
                        <label style={{ fontSize: '22px' }}>Quantity :&nbsp;</label>
                        <div className="quantity">
                          <div className="pro-qty" >
                            <input style={{ color: '#333' }} type="number" min={1} max={product.noOfStock} onChange={(e) => setQuantity(+e.target.value < product.noOfStock ? e.target.value : product.noOfStock)} value={quantity} />
                          </div>
                        </div>
                        </div>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: '#253f4b',
                            marginTop: isMobile ? '20px' : '70px',
                            marginLeft: isMobile ? '0' : '5%',
                          }}
                          color="primary"
                          onClick={handleCart}
                        >
                          Add to Cart
                        </Button>
                      </div>

                      <div className="product__details__last__option">
                        <ul>
                          <li>
                            <span>Category:</span> {product.category}
                          </li>
                          <li>
                            <span>Sub Category:</span> {product.subCategory}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                      {product.productName} has been added to the cart
                    </Alert>
                  </Snackbar>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- Shop Section End --> */}
        </>
      )}
    </>
  );
};

export default SingleProduct;
