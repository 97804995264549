import Footer from "./components/Footer";
import Header from "./components/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Home from "./components/Home";
import EmailVerify from "./components/EmailVerify/EmailVerify";
import Profile from "./components/Profile";
import CustomerForm from "./components/CustomerForm";
import KycForm from "./components/KycForm";
import SupplierForm from "./components/SupplierForm";
// import AdminSignup from "./components/AdminComponents/AdminSignup";
import CategoryForm from "./components/CategoryForm";
import ProductForm from "./components/ProductForm";
// import AdminLogin from "./components/AdminComponents/AdminLogin";
// import AdminHeader from "./components/AdminComponents/AdminHeader";
// import AdminDashboard from "./components/AdminComponents/AdminDashboard";
// import CreateCategories from "./components/AdminComponents/CreateCategories";
// import CreateProducts from "./components/AdminComponents/CreateProducts";
import SingleProduct from "./components/SingleProduct";
import FilteredProduct from "./components/FilteredProduct";
import Cart from "./components/Cart";
import Products from "./components/Products";
import Checkout from "./components/Checkout";
import YourOrders from "./components/YourOrders";
import PaymentSuccess from "./components/PaymentSuccess";
import CategoriesPage from "./components/CategoriesPage";


function App() {

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/'>
          <Route index element={<Home />} />
          <Route path='your-orders' element={<YourOrders />} />
          <Route path='categories' element={<CategoriesPage />} />
          <Route path='products'>
            <Route index element={<Products />} />
            <Route path='search' element={<Products />} />
            <Route path=":productId" element={<SingleProduct />} />
          </Route>
          <Route path="checkout" element={<Checkout />} />
          <Route path="login" element={<Login />} />
          <Route path="cart" element={<Cart />} />
          <Route path="signup" element={<Signup />} />
          <Route path="profile" element={<Profile />} />
          <Route path="kyc-form" element={<KycForm />} />
          <Route path='/payment-success/:transactionId' element={<PaymentSuccess />} />
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
